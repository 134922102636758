/* * {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
  Garamond,serif,
  "Helvetica Neue", Arial, sans-serif;
  } */

input::placeholder {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
    "Helvetica Neue", Arial, sans-serif !important;
}

body {
  --primary-color: #7daafb;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

@media (min-width: 768px) {
  .navbar-brand.abs {
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.container-table {
  padding-right: 0;
  padding-left: 0;
}

/*~~~~~~~~~~~~~Header css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.home-navbar {
  position: relative;
  left: -70px;
}

.header-nav {
  /* top: 30px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar {
  z-index: 1000;
}

@media (max-width: 1100px) {
  .menu-item {
    white-space: nowrap;
    margin: 0 !important;
  }

  .nav-link-white {
    margin-right: 0 !important;
  }
}

@media (max-width: 768px) {
  .header-nav {
    top: 0px !important;
    background-color: black !important;
  }
  
  .navbar-toggler {
    top: 33px;
    right: 20px;
    text-decoration: none;
    position: absolute;
    background-color: white !important;
    transition: background-color 0.3s;
  }

  .home-navbar {
    left: 0;
  }

  .home-navbar .nav-link.nav-link-black {
    color: white !important;
    text-decoration: none !important;

  }

  .home-navbar .nav-link.nav-link-black:hover {
    background-color: white;
    color: black !important;
    text-decoration: none !important;
    border-bottom: 5px solid #C82A33;
  }

  /* .home-navbar .nav-link.nav-link-black {
    border-bottom: 1px solid white;
  } */

  .home-navbar .nav-link.nav-link-white:hover {
    background-color: white;
    color: #FF000F  !important;
    text-decoration: none !important;
    /* border-bottom: 5px solid #C82A33; */
  }

  /* .home-navbar .nav-link.nav-link-white {
    border-bottom: 1px solid white;
  } */

}


/* .navbar-nav .nav-item {
  margin-right: 0px;
} */

.menu-item {
  white-space: nowrap;
  margin: 1rem;
}

.nav-link-white {
  color: #FFFFFF !important;
  font-size: 24px;
  font-weight: bold;
}

.nav-link-black {
  color: #000000 !important;
  font-size: 24px;
  font-weight: bold;
  margin-right: 2rem !important;
}

.nav-link-black:hover {
  color: #FF000F !important;
}

.nav-link-white:hover {
  color: #FF000F !important;
}

.navbar-nav .nav-link.active {
  color: #FF000F !important;
  font-weight: bold;
}

.home-title-p {
  font-weight: 400;
  color: #DE202A;
  font-size: 96px !important;
}

/*~~~~~~~~~~~~~Home css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.home-bg-image {
  background-image: url("../assets/images/background.png");
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  height: 100vh;
}

.home-bg-image2 {
  background-image: url("../assets/images/background1.png");
  /* background-size: 100% 100%; */
  background-size: contain; 
  background-position: center center;
  background-color: black;
  background-repeat: no-repeat;
  height: 105vh;
}

.home-bg-image3 {
  background-image: url("../assets/images/maps.png");
  background-size: contain;
  background-color: black;
  background-repeat: no-repeat;
  height: 100vh;
  left: 100px !important;
}

.home-btn {
  color: red !important;
  background-color: white !important;
  border-color: white !important;
  border-radius: 4px !important;
  margin-top: 10px;
  font-weight: bold;
  width: 186px;
}

.home-btn:hover {
  color: white !important;
  background-color: #C82A33 !important;
}

.home-section-p {
  color: #FFF;
  font-size: 24px !important;
  font-weight: 100;
}

/* .icon-container {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  top: 2160px;
  left: 150px;
  width: 320px;
  height: 320px;
  border-radius: 51px;
} */

.icon-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 5vh;
  border-radius: 51px;
}

.icon-containers {
  top: 2205px;
  left: 270px;
  width: 320px;
  height: 320px;
  position: absolute;
  height: 125px;
}

.section-icon {
  float: right;
  width: 90%;
  font-weight: bold;
  color: #FFF;
  font-size: 52px !important;
}

.section-icons {
  float: right;
  width: 90% !important;
  font-weight: bold;
  color: #FFF;
  font-size: 56px;
  position: relative;
  z-index: 9;

  /* float: right;
    width: 44% !important;
    top: -80vh;
    font-weight: bold;
    color: #FFF;
    font-size: 56px;
    position: relative;
    z-index: 9; */
}

.img-rightside {
  width: 90% !important;
  position: relative !important;
  margin-left: 100px;
  /* left: 75px; */
}

.img-downside {
  width: 17% !important;
  position: relative !important;
  top: 67vh;
  left: 170px;
}

.section-top {
  top: 95px;
}

@media only screen and (max-width: 768px) {
  .home-bg-image {
    background-size: contain !important;
    background-size: 100% 100% !important;
  }

  .img-rightside {
    width: 75% !important;
    position: relative !important;
    left: 0px !important;
    margin-left: 0px !important;
  }

  /* .image-map {
    top: 405vh;
    height: auto;
    left: 50vh;
    position: static !important;
    max-width: none !important;
  } */
  /* .image-map {
    position: static;
    max-width: 100%;
    transform: none;
  } */

  /* .home-red-title {
    font-size: 45px !important;
  }

  .home-title {
    font-size: 40px !important;
  }

  .home-subtitle {
    font-size: 25px !important;
  } */

  .home-bg-image2 {
    height: 40vh !important;
  }

  .section-top {
    top: 0px !important;
    display: contents;
  }

  .home-section-p {
    padding-right: 0% !important;
    float: none !important;
    text-align: justify !important;
  }

  .section-icon span {
    font-size: 32px !important;
  }

  .section-icons {
    float: right;
    font-weight: bold;
    color: #FFF;
    font-size: 35px !important;
  }


}

/* @media (min-width: 1200px) {
  .home-red-title {
    font-weight: 800;
    color: #C82A33;
    font-size: 50px !important;
  }
  
  .home-title {
    font-weight: 800;
    color: #FFF;
    font-size: 90px !important;
  }
  
  .home-subtitle {
    font-weight: 400;
    color: #FFF;
    font-size: 65px !important;
  }
  
} */

/* @media (max-width: 1440px) and (max-height: 900px) {
  .home-red-title,
  .home-title {
    font-size: 72px; 
  }

  .home-subtitle {
    font-size: 36px;
  }
} */


/*~~~~~~~~~~~~~About us css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.landingPage-bg-image {
  background-image: url("../assets/images/about.png");
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  height: 100vh;
}

/* .navbar-nav .nav-item {
    margin-right: 40px; 
} */

.span-color {
  color: red;
  font-size: 100px;
}

.span-colors {
  color: red;
  font-size: 200px;
}

.padding {
  padding-top: 400px;
}

.text-content {
  max-width: 100%; 
  margin-left: auto; 
  margin-right: auto;
  text-align: center; 
}

.landing-text-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.text-center {
  text-align: left !important;
  font-size: 10px;
}

.section-containers {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  height: 85vh;
}

.section-title-containers {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  height: 85vh;
}

.section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: black !important;
}

.section-maping-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 180vh;
  background-color: black !important;
}

.section-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  background-color: black !important;
}

.section-map-container {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 170vh;
  background-color: black !important;
}

.image-container {
  flex: 1;
}

.image-container img {
  width: 100%;
}

.line-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}

.lines-map-container {
  display: flex;
  align-items: center;
  padding-bottom: 45px;
  padding-top: 30px;
  position: absolute;
  background-color: black;

  @media (min-width: 1080px) {
    top: auto;
  }
}

.lines-first-container {
  display: flex;
  align-items: center;
  padding-bottom: 45px;
  padding-top: 30px;
  position: absolute;

  @media (min-width: 1080px) {
    top: auto;
  }
}

.lines-container {
  display: flex;
  align-items: center;
  padding-bottom: 45px;
  padding-top: 60px;
  position: absolute;

  @media (min-width: 1080px) {
    top: auto;
  }
}

.lines-second-container {
  display: flex;
  align-items: center;
  position: absolute;
  padding-bottom: 45px;
  padding-top: 30px;
  @media (min-width: 1080px) {
    top: auto;
  }
}

.line-profile-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  top: 305vh;
  position: absolute;
}

.line-map-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  top: 393vh;
  position: absolute;
}

.line {
  height: 1px;
  background-color: white;
  width: 160px;
  margin-right: 20px;
}

.system-text {
  color: white;
  font-size: 28px;
  font-weight: 200;
  font-weight: bold;
}

.text-container {
  text-align: left;
}

.img-fluids {
  height: 100vh !important;
  max-width: 100%;
  background-size: cover !important;
}

.img-icon-fluid {
  width: 30%;
  /* width: 45%; */
  max-width: 100%;
  height: auto;
  left: 100px;
  position: relative !important;
}

.img-icon-fluids {
  width: 40%;
  /* width: 45%; */
  max-width: 100%;
  height: auto;
  left: 100px !important;
  position: relative !important;
}

.text-container {
  flex: 1;
  /* padding: 120px;  */
  text-align: left;
}

.text-containers {
  flex: 1;
  /* padding: 120px;  */
  padding-left: 45px;
  text-align: left;
  bottom: 80px;
  position: relative;
}

.about-h1 {
  font-weight: bold;
  font-size: 56px;
  text-align: left;
  color: #FFF;
}

.about-p {
  font-size: 24px;
  font-weight: 100;
  text-align: left;
  color: #FFF;
}

.section-h1 {
  font-weight: bold;
  color: #FFF;
  text-align: left;
  /* font-size: 72px; */
}

.section-left-h1 {
  font-weight: bold;
  color: #FFF;
  font-size: 52px;
  float: right;
  width: 79%;
  bottom: 80px;
}

.section-right-h1 {
  font-weight: bold;
  color: #FFF;
  font-size: 52px;
  float: right;
  width: 83%;
}

.home-red-title {
  font-weight: bold;
  color: #C82A33;
  /* font-size: 96px; */
}

.home-title {
  font-weight: bold;
  color: #FFF;
  /* font-size: 96px; */
}

.home-subtitle {
  font-weight: 400;
  color: #FFF;
  /* font-size: 48px; */
}

.section-title {
  font-weight: 900;
  color: #FFF;
  font-size: 96px !important;
}

.sections-title {
  font-weight: 900;
  color: #DE202A;
  font-size: 96px !important;
}

.section-subtitle {
  font-weight: bold;
  color: #FFF;
  font-size: 60px !important;
}

.section-p {
  color: #FFF;
  font-size: 28px;
  font-weight: 100;
  text-align: left;
}

.section-left-p {
  color: #FFF;
  font-size: 24px !important;
  font-weight: 100;
  float: right;
  width: 83%;
  margin-top: 10px;
  padding-right: 29%;
}

.section-icon-title {
  font-size: 24px;
  font-weight: 100;
  text-align: left;
}

.section-right-p {
  color: #FFF;
  font-size: 22px !important;
  font-weight: 100;
  float: right;
  width: 83%;
  margin-top: 10px;
  padding-right: 12%;
}


.black-bg {
  background-color: black;
}

@media only screen and (max-width: 768px) {
  .landingPage-bg-image {
    background-size: contain !important;
    background-size: 100% 100% !important;
  }

  .section-title-containers {
    height: auto !important;
  }

  .section-maping-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto !important;
    padding-bottom: 20px !important;
    background-color: black !important;
  }

  .text-containers {
    padding-left: 20px !important;
    bottom: 100px !important;
  }

  .section-title {
    font-size: 55px !important;
  }

  .sections-title {
    font-size: 50px !important;
  }

  .home-title-p {
    font-size: 50px !important;
  }

  .section-subtitle {
    font-size: 30px !important;
  }

  .section-h1 {
    padding-right: 0% !important;
    float: none !important;
    font-weight: bold;
    font-size: 35px !important;
    text-align: center !important;
  }

  .section-p {
    font-size: 24px;
    font-weight: 100;
    padding-right: 0% !important;
    float: none !important;
    text-align: justify !important;
  }

  .section-right-h1 {
    font-size: 20px !important;
    padding-right: 0% !important;
    padding-left: 10% !important; 
    font-size: 40px !important;
    text-align: center !important;
    float: none !important;
  }

  .section-right-p {
    width: 98% !important;
    font-size: 20px !important;
    padding-right: 0% !important;
  }

  .img-fluids {
    height: 40vh !important;
    max-width: 100% !important;
    object-fit: cover;
  }

  .section-container {
    height: auto !important;
    min-height: 100vh !important;
  }

  .image-container,
  .text-container {
    padding: 10px;
  }

  .black-bg {
    padding-bottom: 50px;
  }

  .line {
    margin-right: 10px;
  }

  .system-text {
    font-size: 20px;
  }

  .img-icon-fluids {
    left: 0px !important;
  }

  .img-icon-fluid {
    left: 0px !important;
  }

  .about-h1 {
    padding-right: 0% !important;
    float: none !important;
    font-weight: bold;
    font-size: 35px !important;
    text-align: center !important;
  }

  .about-p {
    font-size: 24px;
    font-weight: 100;
    padding-right: 0% !important;
    float: none !important;
    text-align: justify !important;
  }

  .section-left-p {
    color: #FFF;
    font-size: 24px !important;
    font-weight: 100;
    float: right;
    text-align: justify !important;
    margin-top: 10px;

    width: auto !important; 
    padding-right: 0 !important; 
  }

}



/*~~~~~~~~~~~~~OSIRIS css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.osiris-bg-image {
  background: transparent linear-gradient(180deg, #FF88884F 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;

  @media (min-width: 1080px) {
    height: auto;
  }
}

.section-osiris-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
}

.section-background {
  background-color: #FFF7F7;
}

.about-caption {
  text-align: center;
}

.osiris-padding {
  padding-top: 6%;
}

.lines-osiris-container {
  display: flex;
  align-items: center;
  padding-bottom: 45px;
  padding-top: 25px;
  position: relative; 

  @media (min-width: 1080px) {
    top: auto;
  }
}

.line-osiris {
  height: 1px;
  width: 160px;
  background-color: black;
  margin-right: 20px;
}

.system-osiris-text {
  font-size: 28px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .lines-osiris-container {
    margin-bottom: 20px;
    justify-content: left;
  }

  .line-osiris {
    margin-right: 10px;
  }
}

.osiris-header-h1 {
  font-weight: bold;
  font-size: 56px;
  margin-right: 15%;
  margin-left: 15%;
}

.osiris-header-p {
  font-size: 24px;
  font-weight: 100;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
}

.osiris-h1 {
  font-weight: bold;
  font-size: 56px;
  text-align: left;
}

.osiris-p {
  font-size: 24px;
  font-weight: 100;
  text-align: left;
}

.osiris-right-h1 {
  padding-right: 30%;
  float: left;
  font-weight: bold;
  font-size: 35px;
}

.osiris-right-p {
  font-size: 24px;
  font-weight: 100;
  padding-right: 20%;
  float: left;
}

.osiris-left-h1 {
  padding-right: 30%;
  float: left;
  font-weight: bold;
  font-size: 35px;
}

.osiris-left-p {
  font-size: 24px;
  font-weight: 100;
  padding-right: 20%;
  float: left;
}

.section-table-icon {
  width: 15%;
}

.section-table-h1 {
  /* margin-left: 45px; */
  font-weight: bold;
  font-size: 56px;
}

.section-table-h3 {
  font-weight: bold;
}

.section-table-p {
  font-size: 24px;
}

.table-container {
  padding-left: 100px;
  padding-right: 100px;
}

.table-containers {
  padding-left: 100px;
}

@media only screen and (max-width: 768px) {
  .osiris-header-h1 {
    font-weight: bold;
    font-size: 25px !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }

  .osiris-header-p {
    font-size: 24px;
    font-weight: 100;
    margin-right: 0% !important;
    margin-left: 0% !important;
    text-align: justify;
  }

  .osiris-h1 {
    padding-right: 0% !important;
    float: none !important;
    font-weight: bold;
    font-size: 35px !important;
    text-align: center !important;
  }

  .osiris-p {
    font-size: 24px;
    font-weight: 100;
    padding-right: 0% !important;
    float: none !important;
    text-align: justify !important;
  }

  .section-table-h1 {
    font-size: 35px !important;
  }

  .table-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

  .table-containers {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

/*~~~~~~~~~~~~~ONTRAQ css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.ontraq-bg-image {
  background: transparent linear-gradient(180deg, #214AED4F 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;

  @media (min-width: 1080px) {
    height: auto;
  }
}

.section-ontraq-background {
  background-color: #5979FC0F;
}

.ontraq-header-h1 {
  font-weight: bold;
  font-size: 56px;
}

.ontraq-header-p {
  font-size: 24px;
  font-weight: 100;
  text-align: center;
}

.img-mobile {
  width: 100%;
  object-fit: cover !important;
}

@media only screen and (max-width: 768px) {
  .img-mobile {
    max-width: 100% !important;
  }

}

/*~~~~~~~~~~~~~PARENTLINE css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.parentline-bg-image {
  background: transparent linear-gradient(180deg, #00711E4F 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;

  @media (min-width: 1080px) {
    height: auto;
  }
}

.section-parentline-background {
  background: #F1F7F2 0% 0% no-repeat padding-box;
}

.header-section1 {
  margin-left: 55px;

  @media (min-width: 1080px) {
    margin-left: 0px !important;

  }
}

.parentline-header-h1 {
  font-weight: bold;
  font-size: 56px;
}

.parentline-header-p {
  font-size: 24px;
  font-weight: 100;
  text-align: center;
  margin-right: 10%;
  margin-left: 10%;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.font-awesome-icons {
  color: black;
  font-size: 45px;
}

.parentline-h5 {
  color: #7CB130;
  font-weight: bold;
}

.img-parentline-logo {
  padding-top: 20vh;
}


/*~~~~~~~~~~~~~Support css~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.support-bg-image {
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  height: auto;
  overflow-x: hidden;

  @media (min-width: 768px) {
    height: 100vh;
  }
}

.support-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  background-color: black;
}

.support-btn {
  background: #D8282F 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  bottom: 15px !important;
  width: 23vh;
  border: none !important;
  transition: background-color 0.3s !important;
}

.support-btn:hover {
  background-color: #9c2023 !important;
}

.font-awesome-icon {
  color: white;
  font-size: 35px;
}

.form-group .support-form {
  border: 1px solid #707070 !important;
  height: 77px !important;
  /* width: 70vh; */
}

.form-group .support-form::placeholder {
  color: #BEBEBE !important;
}

.support-textarea textarea {
  border: 1px solid #707070 !important;
  /* width: 70vh; */
  resize: none;
}

.support-textarea::placeholder {
  color: #BEBEBE !important;
}

@media (max-width: 768px) {
  .form-group .support-form {
    border: 1px solid #707070 !important;
    height: 77px !important;
    width: 30vh;
  }

  .support-textarea {
    border: 1px solid #707070 !important;
    height: 150px !important;
    width: 30vh;
  }

  .section-support-h1 {
    font-size: 41px !important;
  }
  
  .section-support-p {
    font-size: 23px !important;
  }
}

@media (max-width: 768px) {
  .section-support-h1 {
    font-size: 41px !important;
  }

  .section-support-p {
    font-size: 23px !important;
  }

  .form-group .support-form,
  .support-textarea {
    width: 100% !important;
  }
}

.section-support-h1 {
  font-weight: 800;
  color: #FFF;
  font-size: 90px;
}

.section-support-p {
  font-weight: 300;
  color: #FFF;
  font-size: 56px;
  margin-bottom: 80px !important;
}

.section-supportsub-p {
  color: #FFF;
  font-size: 32px !important;
}

.section-supportsubs-p {
  font-weight: 800;
  color: #FFF;
  font-size: 32px !important;
  margin-bottom: 80px !important;
}

.row-part {
  margin-top: 50px;
}

.form-icons {
  padding-bottom: 20px;
}


/*~~~~~~~~~~~~~Footer Dark scss~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.landingPage-footers {
  overflow-x: hidden !important;

  &.landingPage-footer-bg {
    background-color: black;
    color: white;
    /* padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px; */

    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
  }

  &.p-24 {
    font-size: 26px;
    color: white;
    font-weight: 100;
  }

  &.p-18 {
    font-size: 20px;
    color: white;
    font-weight: 100;
  }

  &.p-left-p {
    font-size: 24px;
    color: white;
    font-weight: bold;
  }

  &.p-28 {
    color: white;
    font-size: 24px;
    font-weight: 100;
  }

  &.img-padding {
    padding-right: 18px;
  }

  &.p-20 {
    color: white;
    font-size: 20px;
    font-weight: 100;
  }

  &.textAlign {
    text-align: center;
  }

  &.paddingTop-20 {
    color: white;
    padding-top: 20px;
  }

  .landingPage-base1 {
    padding-left: 0px;
    margin-left: 3px;
  }

  .footer-img-fluids {
    width: 19%;
  }

  .landingPage-base2 {
    padding-right: 0px;
    margin-right: -375px;
  }


  @media (max-width: 768px) {
    &.landingPage-footer-bg {  
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    .landingPage-base2 {
      padding-right: 0px;
      margin-right: 0px;
    }

    &.p-18 {
      font-size: 15px;
      color: white;
    }

    &.landingPage-footer-bg {
      background-color: black;
      color: white;
    }

    .footer-img-fluid {
      width: 35%;
    }

    .footer-sections {
      display: block !important;
    }

    .section-footer-text {
      margin-right: 0rem !important;
      font-size: large;
    }
  }

  .form-control {
    background: white;
    border-radius: 9px;
    border: transparent;
    height: 37px;
    color: black;
    opacity: 1;
    max-width: 100%;
  }

  .input-group-text {
    background: white;
    border: transparent;
    color: black;
    opacity: 1;
  }

  input::placeholder {
    color: black;
  }

  .footer-img-fluid {
    width: 100%;
  }

}

ul.list-unstyled.footer-sections a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

ul.list-unstyled a:hover {
  text-decoration: overline;
}

.section-footer-text {
  margin-right: 2rem !important;
  font-size: large;
}

.black-border {
  border-top: 2px solid black;
}

/*~~~~~~~~~~~~~Footer light scss~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.landingPage-footer {
  overflow-x: hidden !important;

  &.landingPage-footer-bg {
    background-color: white;
    color: black;
    /* padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px; */

    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
  }

  &.p-24 {
    font-size: 26px;
    font-weight: 100;
    color: black;
  }

  &.p-18 {
    font-size: 20px;
    font-weight: 100;
    color: black;
  }

  &.p-left-p {
    font-size: 24px;
    color: black;
    font-weight: bold;
  }

  &.p-28 {
    color: black;
    font-size: 24px;
    font-weight: 100;
  }

  &.img-padding {
    padding-right: 18px;
  }

  &.p-20 {
    color: black;
    font-size: 20px;
    font-weight: 100;
  }

  &.textAlign {
    text-align: center;
  }

  &.paddingTop-20 {
    color: black;
    padding-top: 20px;
  }

  /* .landingPage-base1 {
      padding-left: 0px;
      margin-left: -7px;
    } */

  .landingPage-base2 {
    padding-right: 0px;
    margin-right: -365px;
  }

  .footer-img-fluid {
    width: 19%;
  }

  @media (max-width: 768px) {
    &.landingPage-footer-bg {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    .landingPage-base2 {
      padding-right: 0px;
      margin-right: 0px;
    }

    &.p-18 {
      font-size: 15px;
      color: black;
    }

    &.landingPage-footer-bg {
      background-color: white;
      color: black;

    }


    .footer-section {
      display: block !important;
    }

    .section-footer-text {
      margin-right: 0rem !important;
      font-size: large;
    }
  }

  .form-control {
    background: black;
    border-radius: 9px;
    border: transparent;
    height: 37px;
    color: white;
    opacity: 1;
    max-width: 100%;
  }

  .input-group-text {
    background: black;
    border: transparent;
    color: white;
    opacity: 1;
  }

  input::placeholder {
    color: white;
  }
}

ul.list-unstyled a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

ul.list-unstyled a:hover {
  text-decoration: overline;
}

.section-footer-text {
  margin-right: 2rem !important;
  font-size: large;
}

.black-border {
  border-top: 2px solid black;
}